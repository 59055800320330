import Box from "@vertikal/e-prospera.ui.box";
import Breadcrumbs, { Breadcrumb } from "@vertikal/e-prospera.ui.breadcrumbs";
import ParseHtml from "@vertikal/e-prospera.ui.parse-html";
import Text from "@vertikal/e-prospera.ui.text";
import { graphql } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { API } from "../api/api";
import GlobalSettings from "../components/GlobalSettings";
import MainMenu from "../components/MainMenu/MainMenu";
import {
  BreadCrumbCurrent,
  BreadCrumbLink,
  BreadCrumbsWrapper,
  TermsContent,
  TermsLayout,
  TermsWrapper,
} from "../styles/terms-of-service.styles";

const TermsOfServicePage = () => {
  const [terms, setTerms] = useState(null);
  const { language } = useI18next();

  useEffect(() => {
    async function getTermsofService() {
      try {
        const req = await fetch(
          `${API}/terms-backend/public/term-definitions?code=TERMS_OF_SERVICE`
        );
        const res = await req.json();
        if (!req.ok) {
          throw new Error(req.status);
        }
        setTerms(res[0]);
      } catch (error) {
        console.log("error", error);
      }
    }
    if (!terms) {
      getTermsofService();
    }
    return;
  });

  return (
    <>
      <GlobalSettings />
      <MainMenu>
        <BreadCrumbsWrapper>
          <Breadcrumbs>
            <Breadcrumb id="breadcrumb-home">
              <BreadCrumbLink href="/" color="gray" variant="p">
                <Trans>common.home</Trans>
              </BreadCrumbLink>
            </Breadcrumb>
            <BreadCrumbCurrent>
              <Trans>terms.terms_and_conditions</Trans>
            </BreadCrumbCurrent>
          </Breadcrumbs>
        </BreadCrumbsWrapper>
        <TermsLayout>
          {!!terms && (
            <TermsWrapper id="terms-of-service_content">
              <Box
                width="100%"
                display="flex"
                flexDirection="column"
                style={{ gap: "1rem" }}
              >
                <Text variant="h3">{terms.name[language]}</Text>
                <Text variant="p">{terms.versionNo}</Text>
                <TermsContent>
                  <ParseHtml>{terms.content[language]}</ParseHtml>
                </TermsContent>
              </Box>
            </TermsWrapper>
          )}
        </TermsLayout>
      </MainMenu>
    </>
  );
};

export default TermsOfServicePage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
